import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Meesho from '../assets/meesho.jpg';
import amazon from '../assets/amazon.jpg';
import flipkart from '../assets/flipkart.jpg';

const articles = [
  {
    id: 'amazon',
    title: 'Amazon Fulfillment',
    description: 'Learn how Amazon helps sellers with efficient fulfillment processes.',
    image: amazon,
  },
  {
    id: 'flipkart',
    title: 'Flipkart Selling Success',
    description: 'Explore Flipkart’s unique seller opportunities and benefits.',
    image: flipkart,
  },
  {
    id: 'meesho',
    title: 'Meesho Seller Growth',
    description: 'Discover how Meesho empowers new sellers to grow their businesses.',
    image: Meesho,
  },
];

const BlogList = () => {
  return (
    <div className="bg-gradient-to-b from-indigo-100 to-white py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-16 text-gray-800">
          Blog Articles
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles.map((article, index) => (
            <motion.div
              key={article.id}
              className="relative bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
              }}
            >
              <img
                src={article.image}
                alt={article.title}
                className="w-full h-48 object-contain"
              />
              <div className="p-6 space-y-4">
                <h2 className="text-2xl font-semibold text-gray-900">{article.title}</h2>
                <p className="text-gray-600">{article.description}</p>
                <Link
                  to={`/blog/${article.id}`}
                  className="inline-block text-indigo-600 hover:text-indigo-500 transition duration-200 font-medium"
                >
                  Read More →
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
