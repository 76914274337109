// components/InfoSection.tsx

import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface Info {
  icon: string;
  title: string;
  description: string;
}

const infoItems: Info[] = [
  {
    icon: "📝",
    title: "How does our system work?",
    description:
      "Our system processes your files securely through high-capacity, dedicated servers, ensuring efficiency and privacy.",
  },
  {
    icon: "📦",
    title: "Can I merge multiple files?",
    description:
      "Yes, you can upload and merge multiple files at once, streamlining your workflow with ease.",
  },
  {
    icon: "🔑",
    title: "Is SKU summarization automatic?",
    description:
      "Yes, our system automatically summarizes SKU, order numbers, and quantities for your uploads.",
  },
  {
    icon: "💸",
    title: "Is the service free?",
    description:
      "Yes, our platform is completely free to use, allowing you to manage your files without any hidden costs or subscription fees.",
  },
  {
    icon: "🛡️",
    title: "Is my file stored on your server?",
    description:
      "No, we do not store your files. All files are automatically deleted 30 minutes after upload.",
  },
  {
    icon: "⚙️",
    title: "Is the platform easy to use?",
    description:
      "Yes, our platform is designed to be user-friendly, allowing you to process and manage files quickly without any technical knowledge.",
  },
];

const InfoSection: React.FC = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.2, // Trigger when 20% of the section is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div ref={ref} className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What makes us great?
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {infoItems.map((info, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center my-5"
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
              transition={{ duration: 0.8, delay: index * 0.3 }}
            >
              <div className="flow-root bg-white rounded-lg px-4 pb-8 shadow-lg h-[250px]">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center p-3 bg-blue-600 rounded-md shadow-lg">
                      <span className="text-white text-3xl">{info.icon}</span>
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                    {info.title}
                  </h3>
                  <p className="mt-5 text-base text-gray-500">
                    {info.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
