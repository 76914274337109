import React from 'react';
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <div className="bg-gradient-to-b from-indigo-100 to-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-12">
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-sm text-indigo-700 font-semibold tracking-wider uppercase">
            About Us
          </h2>
          <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
            Empowering E-commerce Sellers
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-600 mx-auto">
            At Label Cropper, we simplify the order fulfillment process for sellers on Amazon, Flipkart, and Meesho by providing a fast and free tool to crop shipping labels, organize SKUs, and manage orders efficiently.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold text-gray-900">Our Story</h3>
            <p className="mt-2 text-lg text-gray-600">
              Founded in 2023, Label Cropper emerged from the need for a streamlined solution to handle the complexities of e-commerce order management. Our team of experienced developers and e-commerce professionals came together to create a user-friendly tool that addresses the common challenges faced by online sellers.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold text-gray-900">Our Mission</h3>
            <p className="mt-2 text-lg text-gray-600">
              Our mission is to empower e-commerce sellers by providing tools that enhance efficiency and productivity, allowing them to focus on growing their businesses.
            </p>
          </motion.div>
        </div>

        <div className="space-y-10">
          <h3 className="text-2xl font-semibold text-gray-900 text-center">
            Why Choose Us?
          </h3>
          <dl className="space-y-8 md:grid md:grid-cols-2 md:gap-10">
            {[
              {
                title: 'Efficiency',
                description: 'Save time by quickly cropping labels and managing orders.',
                delay: 0.2,
              },
              {
                title: 'User-Friendly',
                description: 'Our intuitive interface ensures a seamless experience.',
                delay: 0.4,
              },
              {
                title: 'Free to Use',
                description: 'Access all features without any cost.',
                delay: 0.6,
              },
              {
                title: 'Continuous Improvement',
                description: 'We regularly update our tool based on user feedback.',
                delay: 0.8,
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="relative p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: item.delay }}
              >
                <dt className="flex items-center space-x-4">
                  <div className="flex-shrink-0 h-12 w-12 rounded-full bg-indigo-500 text-white flex items-center justify-center">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="text-xl font-medium text-gray-900">
                    {item.title}
                  </p>
                </dt>
                <dd className="mt-2 text-gray-600">{item.description}</dd>
              </motion.div>
            ))}
          </dl>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="text-center"
        >
          <h3 className="text-2xl font-semibold text-gray-900">Get in Touch</h3>
          <p className="mt-4 text-lg text-gray-600">
            We’d love to hear from you! Whether you have questions, feedback, or need assistance, feel free to{' '}
            <a
              href="mailto:support@lablecropper.com"
              className="text-indigo-600 hover:text-indigo-500 transition duration-200"
            >
              contact us
            </a>
            .
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutUs;
