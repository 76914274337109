import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import logo from '../../assets/logo.png';

const Header: React.FC = () => {
  const location = useLocation(); // Get the current location
  const [menuOpen, setMenuOpen] = useState(false); // State to control mobile menu toggle

  const isActive = (path: string) => {
    return location.pathname === path ? "text-blue-600" : "text-gray-700";
  };

  return (
    <header className="flex justify-between items-center p-4 md:p-6 bg-white shadow-md relative">
      {/* Left side: Logo */}
      <div className="flex items-center space-x-2">
        <Link to="/" className="text-xl font-bold">
          <img className="hidden md:block" src={logo} alt="logo" width={250} />
        </Link>
      </div>

      {/* Hamburger menu icon for mobile */}
      <div className="md:hidden">
        <button onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? (
            <CloseOutlined className="text-2xl text-blue-600" />
          ) : (
            <MenuOutlined className="text-2xl text-blue-600" />
          )}
        </button>
      </div>

      {/* Center: Navigation links */}
      <nav
        className={`${
          menuOpen ? "flex" : "hidden"
        } absolute md:relative md:flex md:flex-row flex-col md:space-x-6 space-y-4 md:space-y-0 bg-white md:bg-transparent top-16 md:top-auto left-0 w-full md:w-auto px-4 md:px-0 pb-4 md:pb-0 shadow-md md:shadow-none z-10`}
      >
        <Link
          to="/amazon"
          className={`relative pb-2 group font-bold ${isActive(
            "/amazon"
          )} hover:text-blue-600`}
        >
          Amazon
          <span
            className={`absolute left-0 bottom-0 h-[2px] ${
              location.pathname === "/amazon" ? "w-full bg-blue-600" : "w-0"
            } group-hover:w-full group-hover:bg-blue-600 transition-all duration-300`}
          ></span>
        </Link>

        <Link
          to="/flipkart"
          className={`relative pb-2 group font-bold ${isActive(
            "/flipkart"
          )} hover:text-blue-600`}
        >
          Flipkart
          <span
            className={`absolute left-0 bottom-0 h-[2px] ${
              location.pathname === "/flipkart" ? "w-full bg-blue-600" : "w-0"
            } group-hover:w-full group-hover:bg-blue-600 transition-all duration-300`}
          ></span>
        </Link>

        <Link
          to="/meesho"
          className={`relative pb-2 group font-bold ${isActive(
            "/meesho"
          )} hover:text-blue-600`}
        >
          Meesho
          <span
            className={`absolute left-0 bottom-0 h-[2px] ${
              location.pathname === "/meesho" ? "w-full bg-blue-600" : "w-0"
            } group-hover:w-full group-hover:bg-blue-600 transition-all duration-300`}
          ></span>
        </Link>

        <Link
          to="/about-us"
          className={`relative pb-2 group font-bold ${isActive(
            "/about-us"
          )} hover:text-blue-600`}
        >
          About Us
          <span
            className={`absolute left-0 bottom-0 h-[2px] ${
              location.pathname === "/about-us" ? "w-full bg-blue-600" : "w-0"
            } group-hover:w-full group-hover:bg-blue-600 transition-all duration-300`}
          ></span>
        </Link>

        <Link
          to="/blog"
          className={`relative pb-2 group font-bold ${isActive(
            "/blog"
          )} hover:text-blue-600`}
        >
          Blog
          <span
            className={`absolute left-0 bottom-0 h-[2px] ${
              location.pathname === "/blog" ? "w-full bg-blue-600" : "w-0"
            } group-hover:w-full group-hover:bg-blue-600 transition-all duration-300`}
          ></span>
        </Link>
      </nav>

      {/* Right side: Contact Us button */}
      <div className="hidden md:flex items-center space-x-2">
        <a
          href="mailto:support@lablecropper.com"
          className="border border-blue-600 text-blue-600 px-4 py-1 rounded hover:bg-blue-600 hover:text-white"
        >
          Contact Us
        </a>
      </div>
    </header>
  );
};

export default Header;
