import { createBrowserRouter } from "react-router-dom";
import FileUpload from "./Components/FileUpload"; // Adjust the path as necessary
import Layout from "./Components/layout"; // Adjust the path as necessary
import React from "react";
import Landing from "./Components/UI/landing";
import Amazon from "./Components/E-commerce/amazon";
import Flipkart from "./Components/E-commerce/flipkart";
import Meesho from "./Components/E-commerce/meesho";
import AboutUs from "./Components/about";
import BlogList from "./Components/blog";
import BlogDetail from "./Components/blogDetails";
import TermsAndConditions from "./Components/tnc";
import PrivacyPolicy from "./Components/privacy";

const router = createBrowserRouter([
  {
    path: "/",
    element: React.createElement(Layout, null, React.createElement(Landing))
  },
  {
    path: "/amazon",
    element: React.createElement(Layout, null, React.createElement(Amazon))
  },
  {
    path: "/flipkart",
    element: React.createElement(Layout, null, React.createElement(Flipkart))
  },
  {
    path: "/meesho",
    element: React.createElement(Layout, null, React.createElement(Meesho))
  },
  {
    path: "/about-us",
    element: React.createElement(Layout, null, React.createElement(AboutUs))
  },
  {
    path: "/blog",
    element: React.createElement(Layout, null, React.createElement(BlogList))
  },
  {
    path: "/blog/:id",  // Corrected path for dynamic route
    element: React.createElement(Layout, null, React.createElement(BlogDetail))
  },
  {
    path: "/privacy-policy",
    element: React.createElement(Layout, null, React.createElement(PrivacyPolicy))
  },
  {
    path: "/terms-and-conditions",
    element: React.createElement(Layout, null, React.createElement(TermsAndConditions))
  },
]);

export default router;
