import React, { useState, useRef, useEffect } from "react";
import { Upload, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import LeftSide from "../leftSide";
import RightSide from "../rightSide";
import axios from "axios";
import NProgress from "nprogress"; // Import NProgress
import "nprogress/nprogress.css"; // Import NProgress CSS
import type { UploadFile } from "antd/es/upload/interface";

const Meesho = () => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [platform, setPlatform] = useState<string>('meesho');
  const [processedKeys, setProcessedKeys] = useState<string[]>([]);
  const [summury , setSummuryTable] = useState(5)
  const [isChecked, setIsChecked] = useState(true);
  const [downloadLinks, setDownloadLinks] = useState<
    {
      filename: string;
      url: string;
      original_filename: string;
      page_count: any;
    }[]
  >([]);
  const [hideProceed, setHideProceed] = useState(false);
  const [mergeFiles, setMergeFiles] = useState(true);
  const [loading, setLoading] = useState(false);
  const uploadedFilesRef = useRef<UploadFile[]>([]);

  useEffect(() => {
    if (files.length === 0) {
      setHideProceed(false);
    }
  }, [files]);

  const startLoading = () => {
    NProgress.start();
    setLoading(true);
  };

  const stopLoading = () => {
    NProgress.done();
    setLoading(false);
  };

  const uploadProps = {
    multiple: true,
    beforeUpload: (file: File) => {
      return false;
    },
    onChange: async (info: any) => {
      const selectedFiles = info.fileList;

      const newFiles = selectedFiles.filter(
        (file: any) =>
          !uploadedFilesRef.current.find((f) => f.uid === file.uid) &&
          file.status !== "error"
      );

      if (newFiles.length === 0) return;

      uploadedFilesRef.current = [...uploadedFilesRef.current, ...newFiles];
      setFiles(selectedFiles);
      setHideProceed(false);
      startLoading();

      try {
        const uploadPromises = newFiles.map((file: any) => uploadFile(file));
        await Promise.all(uploadPromises);
        message.success("All files uploaded successfully");
      } catch (error) {
        message.error("Error uploading one or more files");
        console.error(error);
      }

      stopLoading();
      setHideProceed(true);
    },
    onRemove: (file: UploadFile) => {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((f) => f.uid !== file.uid);
        uploadedFilesRef.current = uploadedFilesRef.current.filter(
          (f) => f.uid !== file.uid
        );
        return updatedFiles;
      });
    },
  };

  const uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file.originFileObj);

    try {
      const response = await axios.post(
        `https://api.lablecropper.com/api/upload/${platform}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(`${file.name} upload progress: ${percentCompleted}%`);
            }
          },
        }
      );
      const { fileKey } = response.data;
      setProcessedKeys((prevKeys) => [...prevKeys, fileKey]);
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      message.error(`Error uploading ${file.name}`);
      console.error(error);
    }
  };

  const handleProceed = async () => {
    if (processedKeys?.length === 0) {
      message.error("Please upload at least one file before proceeding.");
      return;
    }

    const startTime = Date.now();

    try {
      startLoading();
      const response = await axios.post(
        `https://api.lablecropper.com/api/labels/${platform}`,
        {
          keys: processedKeys,
          merge: mergeFiles,
          summury: isChecked,
          summuryCountPage: summury
        }
      );

      const { downloadLinks: newDownloadLinks } = response.data;
      setDownloadLinks(newDownloadLinks);

      stopLoading();

      const endTime = Date.now();
      const timeTaken = ((endTime - startTime) / 1000).toFixed(2);

      message.success(`Files processed successfully in ${timeTaken} seconds.`);
      setFiles([]);
      uploadedFilesRef.current = [];
      setProcessedKeys([]);
    } catch (error) {
      stopLoading();
      message.error(`Error processing files: ${error}`);
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-8 bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen">
      {/* Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800">Process Meesho Shipping Label</h1>
        <p className="text-gray-600 mt-5">
          Easily organize and crop Meesho PDF labels with our tool.
        </p>
      </div>

      <div className="grid grid-cols-12 gap-4">
        {/* Left Section: Features */}
        <LeftSide />

        {/* Center Section: Drag and Drop */}
        <div className="col-span-12 md:col-span-6 flex flex-col items-center">
          <Upload {...uploadProps} fileList={files}>
            <div className="p-10 w-full border-dashed border-4 border-blue-300 rounded-lg text-center cursor-pointer bg-white hover:bg-blue-50 transition">
              <UploadOutlined className="text-3xl text-blue-600 mb-2" />
              <p className="text-blue-600">Drag & Drop your files or Browse</p>
            </div>
          </Upload>

          <div className="text-center mt-4">
            <h2 className="text-xl font-semibold text-blue-600">
              Supported: Meesho A4 labels
            </h2>
            <p className="text-gray-500">
              Note: Crop thermal and A4 labels separately.
            </p>
          </div>

          {loading && (
            <Spin size="large" tip="Uploading..." className="mt-6 text-blue-600" />
          )}
        </div>

        {/* Right Section: Settings and Proceed Button */}
        {/* <RightSide
          mergeFiles={mergeFiles}
          setMergeFiles={setMergeFiles}
          downloadLinks={downloadLinks}
          hideProceed={hideProceed}
          processedKeys={processedKeys}
          loading={loading}
          handleProceed={handleProceed}
          platform={platform}
        /> */}
                      <RightSide
          mergeFiles={mergeFiles}
          setMergeFiles={setMergeFiles}
          downloadLinks={downloadLinks}
          hideProceed={hideProceed}
          processedKeys={processedKeys}
          loading={loading}
          handleProceed={handleProceed}
          platform={platform}
          setSummuryTable={setSummuryTable}
          setIsChecked={setIsChecked}
          summury={summury}
          isChecked={isChecked}
        />
      </div>
    </div>
  );
};

export default Meesho;
