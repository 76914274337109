import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import f from "../assets/f.png";
import c from "../assets/computer-icon.png";

const steps = [
  {
    icon: f, // Replace with your actual icon path
    width: 40,
    height: 40,
    title: 'Download Labels',
    description: 'Get your PDF labels from platforms like Amazon, Flipkart, or other sources.',
  },
  {
    icon: c, // Replace with your actual icon path
    width: 70,
    height: 70,
    title: 'Upload & Select Options',
    description: 'Customize your label cropping options and simply drag and drop the files.',
  },
  {
    icon: f, // Replace with your actual icon path
    width: 40,
    height: 40,
    title: 'Download Processed Labels',
    description: 'Let the system work its magic and download your cropped labels with ease.',
  },
];

const HowItWorksSection: React.FC = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.2, // The animation will trigger when 20% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <div ref={ref} className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            How it Works?
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center"
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
              transition={{ duration: 0.8, delay: index * 0.3 }}
            >
              <motion.div
                className="flex justify-center items-center w-24 h-24 bg-blue-600 rounded-full shadow-lg mb-4"
                whileHover={{ scale: 1.1, rotate: 10 }}
                whileTap={{ scale: 0.95 }}
              >
                <img
                  src={step.icon}
                  alt={step.title}
                  width={step?.width}
                  height={step?.height}
                  className="brightness-0 invert"
                />
              </motion.div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {step.title}
              </h3>
              <p className="text-center text-gray-500">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;
