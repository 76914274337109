const LeftSide = ()=>{
    return(
        <div className="col-span-3 hidden md:block">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Features</h2>
          <ul className="text-gray-600 list-disc ml-5">
            <li>Order Analysis</li>
            <li>Sorted by SKU and Qty</li>
            <li>Add SKU - QTY in Label</li>
            <li>Remove extra blank pages</li>
            <li>Merge and process multiple Amazon labels</li>
          </ul>
        </div>


        <div className="mt-6">
          <h3 className="text-md font-semibold">Need Any help?</h3>
          <p className="text-gray-600">
            Drop a mail at <a href="mailto:support@lablecropper.com" className="text-blue-600">support@lablecropper.com</a>
          </p>
        </div>
      </div>
    )
}

export default LeftSide