import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header */}
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Terms and Conditions</h1>
        <p className="text-gray-600 mb-8">Last Updated: January 6, 2024</p>

        {/* Terms Content */}
        <div className="space-y-6 text-gray-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">1. Acceptance of Terms</h2>
            <p>
              By accessing and using LabelCropper's services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree, please refrain from using our services.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">2. Services Provided</h2>
            <p>
              LabelCropper offers a free online tool for cropping labels and managing orders for Amazon, Flipkart, and Meesho sellers. We reserve the right to modify or discontinue the service at any time without prior notice.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">3. User Responsibilities</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><strong>Accuracy:</strong> Ensure that all information provided is accurate and up-to-date.</li>
              <li><strong>Compliance:</strong> Use our services in compliance with all applicable laws and regulations.</li>
              <li><strong>Prohibited Activities:</strong> Refrain from using our services for any unlawful or unauthorized purposes.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">4. Intellectual Property</h2>
            <p>
              All content, trademarks, and data on this website are the property of LabelCropper or its licensors. Unauthorized use is prohibited.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">5. Limitation of Liability</h2>
            <p>
              LabelCropper is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use our services.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">6. Modifications to Terms</h2>
            <p>
              We may update these Terms and Conditions periodically. Continued use of our services constitutes acceptance of the revised terms.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">7. Governing Law</h2>
            <p>
              These Terms and Conditions are governed by the laws of [Your Jurisdiction].
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">8. Contact Information</h2>
            <p>
              For any questions regarding these Terms and Conditions, please contact us at <a href="mailto:support@labelcropper.com" className="text-blue-600 hover:underline">support@labelcropper.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
