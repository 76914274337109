import { DownloadOutlined, FileMarkdownOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";

const RightSide = ({
    mergeFiles,
    setMergeFiles,
    downloadLinks,
    hideProceed,
    processedKeys,
    loading,
    handleProceed,
    platform,
    setSummuryTable,
    setIsChecked,
    summury,
    isChecked
  }: {
    mergeFiles: boolean,
    setMergeFiles: (checked: boolean) => void,
    downloadLinks: { filename: string; url: string; original_filename: string; page_count:any; }[];
    hideProceed:any,
    processedKeys:any,
    loading:any,
    handleProceed:any,
    platform:any,
    setSummuryTable:any,
    setIsChecked:any,
    summury:any,
    isChecked:any
  }) => {
    // Function to generate the correct filename
    const generateFilename = ( processedFilename: string) => {
     
        // If merge is on, extract the part after the last underscore and prepend "amazon_"
        const filenameAfterUnderscore = processedFilename.split('_').pop(); 
        return `${platform}_${filenameAfterUnderscore}`;
     
    };
  
    // Function to force file download
    const handleDownload = (url: string, originalFilename: string, processedFilename: string) => {
      const filename = generateFilename( originalFilename); // Get the correct filename
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Set the correct filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    };
  console.log(downloadLinks)
    return (
      <div className="col-span-12 md:col-span-3">
        <h2 className="text-xl font-semibold mb-4">Settings</h2>
        <Checkbox
          checked={mergeFiles}
          onChange={(e) => setMergeFiles(e.target.checked)}
        >
          Merge Files
        </Checkbox>
        <div className="flex items-center space-x-2">
        <Checkbox
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        >
        </Checkbox>
      <span className="text-gray-800">Add picklist page after</span>
      <input
        type="number"
        value={summury}
        onChange={(e) => setSummuryTable(parseInt(e.target.value))}
        className="w-16 p-1 border border-gray-300 rounded text-center"
      />
      <span className="text-gray-800">orders</span>
      <span className="text-red-600 font-bold text-xl">!</span>
    </div>
        {hideProceed && processedKeys?.length > 0 && (
          <div className="flex items-center mt-4 col-span-12 justify-center">
            <Button type="primary" loading={loading} onClick={handleProceed}>
              Proceed
            </Button>
          </div>
        )}
        {/* Processed Files */}
        <div className="mt-8">
          <h3 className="text-md font-semibold">
            Processed Files (In last 30 minutes)
          </h3>
          <ul className="text-gray-600">
  {downloadLinks.length > 0 ? (
    downloadLinks.map((link, index) => (
      <li key={index} className="mt-2 border-b border-gray-300 pb-2">
        <div className="flex items-center ">
          {/* Display the page count and label 'Orders' */}
          <div className="font-medium text-black">
            <span>{link.page_count}</span>
            <span className="ml-1">Orders</span>
          </div>

          {/* Display the download link with filename and download icon */}
          <div className="flex items-center space-x-2 ml-5">
            <span
              onClick={() => handleDownload(link.url, link.original_filename, link.filename)}
              className="text-blue-600 cursor-pointer underline"
            >
              {generateFilename(link.original_filename)}
            </span>
            <DownloadOutlined className="text-blue-600 cursor-pointer" />
          </div>
        </div>
      </li>
    ))
  ) : (
    <p>No files processed yet.</p>
  )}
</ul>


        </div>
      </div>
    );
  };
  
  export default RightSide;
  