import React from 'react';
import hero from '../../assets/hero.png'
import FeaturesSection from '../FeaturesSection';
import InfoSection from '../FeaturesSection';
import HowItWorksSection from '../HowItWorksSection';
const Landing = () => {
  return (
    <>
    <div className=' px-4 py-4'>
    <div className='flex items-center justify-around flex-col md:flex-row gap-y-5 mt-10 mb-20 md:mb-0 md:mt-0 max-w-[1280px] mx-auto h-[85vh]'>
      <div className='order-2 md:order-1'>
      <p className='text-4xl max-w-[700px] font-medium text-center md:text-left'>Fast Label Cropping and SKU Summaries for Your Amazon, Flipkart, and Meesho Orders.</p>
      
        <p className='mt-5 text-base max-w-[500px] font-normal text-center md:text-left'>Merge your files, crop labels, and get organized SKU, order, and quantity summaries—all in one place.</p>
      </div>
      
      <img className='order-1 md:order-2' src={hero} width={450}  />
    </div>

    <HowItWorksSection />
    <InfoSection />
   
    </div>
   
    
    </>
  );
};

export default Landing;
