import React, { ReactNode } from "react";
import Header from "./UI/header"; // Adjust the path as necessary
import Footer from "./UI/footer"; // Adjust the path as necessary

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <Header />

      {/* Main content, should take all the height between header and footer */}
      <main className="flex-grow ">
        {children}
      </main>
      

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Layout;
