import React from 'react';
import { Link } from 'react-router-dom';
import { MailOutlined } from "@ant-design/icons";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white py-10 border-t border-gray-200">
      <div className="max-w-[1280px] mx-auto px-4 flex flex-col md:flex-row justify-between items-center md:items-start space-y-8 md:space-y-0">
        
        {/* Left Section: Logo and Copyright */}
        <div className="flex flex-col items-center md:items-start space-y-2">
          <p className="text-gray-600 text-sm">
            © LabelCropper.com, 2021-2024
          </p>
        </div>

        {/* Middle Section: Navigation Links */}
        <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-16 text-center sm:text-left">
          {/* Tools Section */}
          <div className="space-y-2">
            <h3 className="text-gray-800 font-semibold">TOOLS</h3>
            <ul className="space-y-1">
              <li><Link to="/amazon" className="text-gray-600 hover:text-blue-600 transition-colors">Amazon</Link></li>
              <li><Link to="/flipkart" className="text-gray-600 hover:text-blue-600 transition-colors">Flipkart</Link></li>
              <li><Link to="/meesho" className="text-gray-600 hover:text-blue-600 transition-colors">Meesho</Link></li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="space-y-2">
            <h3 className="text-gray-800 font-semibold">LEGAL</h3>
            <ul className="space-y-1">
              <li><Link to="/terms-and-conditions" className="text-gray-600 hover:text-blue-600 transition-colors">Terms & Conditions</Link></li>
              <li><Link to="/privacy-policy" className="text-gray-600 hover:text-blue-600 transition-colors">Privacy Policy</Link></li>
            </ul>
          </div>

          {/* Reach Us Section */}
          <div className="space-y-2">
            <h3 className="text-gray-800 font-semibold">REACH US</h3>
            <a href="mailto:support@labelcropper.com" className="flex items-center justify-center sm:justify-start text-gray-600 hover:text-blue-600 transition-colors">
              <MailOutlined className="mr-2" /> support@labelcropper.com
            </a>
            <div className="flex justify-center sm:justify-start space-x-4 mt-2">
              <a href="https://www.instagram.com" aria-label="Instagram" className="text-gray-600 hover:text-blue-600 transition-colors">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.16c3.2 0 3.584.012 4.85.07 1.17.055 1.86.24 2.297.412.592.23 1.014.507 1.46.953.447.447.724.868.953 1.46.173.436.357 1.127.412 2.296.058 1.268.07 1.652.07 4.853s-.012 3.584-.07 4.85c-.055 1.17-.24 1.86-.412 2.297-.23.592-.507 1.014-.953 1.46-.447.447-.868.724-1.46.953-.436.173-1.127.357-2.296.412-1.268.058-1.652.07-4.853.07s-3.584-.012-4.85-.07c-1.17-.055-1.86-.24-2.297-.412-.592-.23-1.014-.507-1.46-.953-.447-.447-.724-.868-.953-1.46-.173-.436-.357-1.127-.412-2.296C2.172 15.584 2.16 15.2 2.16 12s.012-3.584.07-4.85c.055-1.17.24-1.86.412-2.297.23-.592.507-1.014.953-1.46.447-.447.868-.724 1.46-.953.436-.173 1.127-.357 2.296-.412C8.416 2.172 8.8 2.16 12 2.16zm0-2.16C8.676 0 8.262.013 7.009.07 5.722.127 4.732.283 4.046.55c-.82.308-1.504.723-2.188 1.406-.683.683-1.098 1.367-1.406 2.188-.267.686-.423 1.676-.48 2.963C.013 8.262 0 8.676 0 12s.013 3.738.07 4.991c.057 1.287.213 2.277.48 2.963.308.82.723 1.504 1.406 2.188.683.683 1.367 1.098 2.188 1.406.686.267 1.676.423 2.963.48C8.262 23.987 8.676 24 12 24s3.738-.013 4.991-.07c1.287-.057 2.277-.213 2.963-.48.82-.308 1.504-.723 2.188-1.406.683-.683 1.098-1.367 1.406-2.188.267-.686.423-1.676.48-2.963C23.987 15.738 24 15.324 24 12s-.013-3.738-.07-4.991c-.057-1.287-.213-2.277-.48-2.963-.308-.82-.723-1.504-1.406-2.188-.683-.683-1.367-1.098-2.188-1.406-.686-.267-1.676-.423-2.963-.48C15.738.013 15.324 0 12 0zm0 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zm0 10.162a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.406a1.44 1.44 0 1 1 0-2.88 1.44 1.44 0 0 1 0 2.88z"/>
                </svg>
              </a>
              <a href="https://www.twitter.com" aria-label="Twitter" className="text-gray-600 hover:text-blue-600 transition-colors">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M23.444 4.834a9.536 9.536 0 0 1-2.828.816 4.915 4.915 0 0 0 2.165-2.724 9.846 9.846 0 0 1-3.127 1.237 4.855 4.855 0 0 0-8.448 4.42 13.835 13.835 0 0 1-10.048-5.1 4.822 4.822 0 0 0-.66 2.445 4.827 4.827 0 0 0 2.157 4.037 4.812 4.812 0 0 1-2.202-.616v.062a4.858 4.858 0 0 0 3.886 4.761 4.836 4.836 0 0 1-2.193.084 4.867 4.867 0 0 0 4.536 3.352A9.725 9.725 0 0 1 1.14 19.802a13.733 13.733 0 0 0 7.62 2.239c9.142 0 14.307-7.721 14.307-14.417 0-.223 0-.446-.015-.667A10.306 10.306 0 0 0 24 4.554a9.62 9.62 0 0 1-2.791.784 4.818 4.818 0 0 0 2.12-2.72z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
