import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-10">
        {/* Header */}
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Privacy Policy</h1>
        <p className="text-gray-600 mb-8">Last Updated: January 6, 2024</p>

        {/* Privacy Policy Content */}
        <div className="space-y-6 text-gray-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800">1. Introduction</h2>
            <p>
              LabelCropper is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">2. Information We Collect</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><strong>Personal Information:</strong> Such as name, email address, and contact details when you register or contact us.</li>
              <li><strong>Usage Data:</strong> Information on how you interact with our services, including IP addresses and browser types.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">3. Use of Information</h2>
            <p>We use the collected information to:</p>
            <ul className="list-disc ml-6 space-y-2">
              <li>Provide and maintain our services.</li>
              <li>Improve user experience.</li>
              <li>Communicate updates and respond to inquiries.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">4. Data Sharing</h2>
            <p>
              We do not sell or rent your personal information. We may share data with third-party service providers to assist in delivering our services, subject to confidentiality agreements.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">5. Data Security</h2>
            <p>
              We implement appropriate security measures to protect your information. However, no method of transmission over the internet is 100% secure.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">6. Cookies</h2>
            <p>
              Our website uses cookies to enhance user experience. You can control cookie preferences through your browser settings.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">7. Third-Party Links</h2>
            <p>
              Our services may contain links to third-party websites. We are not responsible for their privacy practices.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">8. Children's Privacy</h2>
            <p>
              Our services are not intended for individuals under the age of 18. We do not knowingly collect information from minors.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">9. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy periodically. Changes will be posted on this page with an updated revision date.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold text-gray-800">10. Contact Us</h2>
            <p>
              For questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@labelcropper.com" className="text-blue-600 hover:underline">support@labelcropper.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
