import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Meesho from '../assets/meesho.jpg';
import amazon from '../assets/amazon.jpg';
import flipkart from '../assets/flipkart.jpg';

// Detailed article data with titles, content, and images
type ArticleId = "amazon" | "flipkart" | "meesho";
const articleData = {
  amazon: {
    title: 'Mastering Amazon Fulfillment: A Comprehensive Guide for Sellers',
    content: (
      <>
        <p>
          Amazon Fulfillment, particularly through the Fulfillment by Amazon (FBA) program, offers sellers a streamlined process to store, pack, and ship products to customers. By leveraging Amazon's extensive logistics network, sellers can ensure timely deliveries and focus on scaling their businesses.
        </p>
        <h3 className="text-2xl font-semibold mt-4">Key Benefits of Amazon Fulfillment:</h3>
        <ul className="list-disc ml-6 mt-2 space-y-2">
          <li><strong>Prime Eligibility:</strong> Products fulfilled by Amazon are eligible for Amazon Prime, providing faster shipping options and increasing product visibility to Prime members.</li>
          <li><strong>Customer Service Management:</strong> Amazon handles customer inquiries, returns, and refunds, ensuring a seamless experience for buyers.</li>
          <li><strong>Storage Solutions:</strong> Sellers can store products in Amazon's fulfillment centers, reducing the need for personal storage space.</li>
        </ul>
        <h3 className="text-2xl font-semibold mt-4">Steps to Utilize Amazon Fulfillment:</h3>
        <ol className="list-decimal ml-6 mt-2 space-y-2">
          <li><strong>Enroll in FBA:</strong> Register for the FBA program through your Amazon Seller Central account.</li>
          <li><strong>Prepare Products:</strong> Ensure products meet Amazon's packaging and labeling requirements.</li>
          <li><strong>Ship to Amazon:</strong> Send your inventory to Amazon's designated fulfillment centers.</li>
          <li><strong>Monitor Inventory:</strong> Use Seller Central to track inventory levels and manage restocking.</li>
        </ol>
        <p className="mt-4">
          By integrating Amazon Fulfillment into your sales strategy, you can enhance customer satisfaction and streamline operations, allowing you to focus on business growth.
        </p>
      </>
    ),
    image: amazon,
  },
  flipkart: {
    title: 'Achieving Success on Flipkart: Strategies for Sellers',
    content: (
      <>
        <p>
          Flipkart, one of India's leading e-commerce platforms, offers sellers a vast marketplace to reach millions of customers. To maximize success on Flipkart, sellers should implement effective strategies tailored to the platform's unique ecosystem.
        </p>
        <h3 className="text-2xl font-semibold mt-4">Strategies for Success on Flipkart:</h3>
        <ul className="list-disc ml-6 mt-2 space-y-2">
          <li><strong>Optimize Product Listings:</strong> Use high-quality images, detailed descriptions, and relevant keywords to enhance product visibility.</li>
          <li><strong>Competitive Pricing:</strong> Analyze market trends and competitor pricing to set attractive prices without compromising profit margins.</li>
          <li><strong>Leverage Flipkart's Fulfillment Services:</strong> Utilize Flipkart's fulfillment options to ensure timely deliveries and gain customer trust.</li>
          <li><strong>Participate in Promotions:</strong> Engage in Flipkart's promotional events and sales to boost product exposure and sales volume.</li>
          <li><strong>Maintain High Seller Ratings:</strong> Provide excellent customer service, manage returns efficiently, and encourage positive reviews to build a strong seller reputation.</li>
        </ul>
        <p className="mt-4">
          By focusing on these strategies, sellers can enhance their presence on Flipkart and achieve sustained success in the competitive e-commerce landscape.
        </p>
      </>
    ),
    image: flipkart,
  },
  meesho: {
    title: 'Expanding Your Business with Meesho: A Guide for Sellers',
    content: (
      <>
        <p>
          Meesho, a rapidly growing social commerce platform, empowers sellers to reach a broad audience through social networks. By leveraging Meesho's unique model, sellers can expand their businesses with minimal investment.
        </p>
        <h3 className="text-2xl font-semibold mt-4">Advantages of Selling on Meesho:</h3>
        <ul className="list-disc ml-6 mt-2 space-y-2">
          <li><strong>Zero Commission Model:</strong> Meesho offers a zero-commission structure, allowing sellers to retain full profits from sales.</li>
          <li><strong>Access to a Wide Network:</strong> Sellers can tap into Meesho's extensive reseller network, increasing product reach and sales potential.</li>
          <li><strong>User-Friendly Interface:</strong> The platform provides an intuitive interface for listing products and managing orders.</li>
        </ul>
        <h3 className="text-2xl font-semibold mt-4">Tips for Growth on Meesho:</h3>
        <ul className="list-disc ml-6 mt-2 space-y-2">
          <li><strong>Diversify Product Range:</strong> Offer a variety of products to cater to different customer preferences and increase sales opportunities.</li>
          <li><strong>Ensure Quality and Reliability:</strong> Maintain high product quality and reliable shipping to build trust with resellers and customers.</li>
          <li><strong>Engage with Resellers:</strong> Provide support and information to resellers to help them effectively market your products.</li>
        </ul>
        <p className="mt-4">
          By embracing Meesho's platform and focusing on these growth strategies, sellers can expand their businesses and reach new customer segments.
        </p>
      </>
    ),
    image: Meesho,
  },
};

const BlogDetail = () => {
    const { id } = useParams<{ id: ArticleId }>(); // Specify that id is of type ArticleId

    // Retrieve the article based on id
    const article = id ? articleData[id] : undefined;
  
    if (!article) {
      return <p>Article not found</p>;
    }

  return (
    <div className="bg-gradient-to-b from-indigo-100 to-white py-16 px-4 min-h-screen">
      <div className="max-w-4xl mx-auto">
        <Link
          to="/blog"
          className="inline-block text-indigo-600 hover:text-indigo-500 mb-8 text-lg font-medium"
        >
          &larr; Back to Articles
        </Link>
        
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold text-gray-900 mb-6 text-center">
            {article.title}
          </h1>
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-64 object-cover rounded-lg shadow-md mb-8"
          />
          <div className="text-lg text-gray-700 leading-relaxed space-y-4">
            {article.content}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BlogDetail;
